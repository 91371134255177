
@media print {
@page {
    size: landscape;
}
.no-print {
    display: none !important;
}
[dir] .table th, [dir] .table td {
    border: 1px solid #dee2e6 !important;
}
}
